import React from "react";

export function ContactUsSection() {
  return (
    <div id="contact-us-section">
      <h2 id="contact" className="padding-contact">
        <a id="contact-us" />
        contact Us
      </h2>
      <h4>Contact me on A.h.fensome@gmail.com</h4>
    </div>
  );
}
