import React from "react";
import { ProfilePictureFlexSection } from "./ProfilePictureFlexSection";

export function AboutSection() {
  return (
    <div id="about-section">
      <h2 id="about" className="padding-about">
        <a id="About" />
        About
      </h2>

      <ProfilePictureFlexSection />
    </div>
  );
}
