import React from "react";
import CodeImage from "../Images/code.png";
import ResponsiveAppBar from "./ResponsiveAppBar";

export function HeaderSection() {
  return (
    <>
      <ResponsiveAppBar />
      <img className="image-full image-absolute" src={CodeImage} />
      <div className="typewriter header-area">
        <h1>Cumulus Stack</h1>
      </div>
      <div className="fade-in">
        <h2>
          Software Consulting, Full-stack Development and Data Engineering
        </h2>
      </div>
    </>
  );
}
