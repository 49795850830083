import React from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AboutSection } from "./Components/AboutSection";
import { HeaderSection } from "./Components/HeaderSection";
import { ContactUsSection } from "./Components/ContactUsSection";
import { Footer } from "./Components/Footer";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <HeaderSection />
        <AboutSection />
        <ContactUsSection />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
