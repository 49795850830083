import React from "react";
import { GithubLink } from "./GithubLink";

export function Footer() {
  return (
    <div id="footer" className="footer flexible-container">
      <div>
        <p className="small-text">
          CUMULUS STACK LTD
          <br />
          Company Number: 13967320
          <br />
          VAT Number: 405 6384 03
        </p>
      </div>
      <div>
        <p className="small-text">
          6 St. Cross Road,
          <br />
          Winchester,
          <br />
          SO23 9HX
        </p>
      </div>
      <div>
        <p className="small-text">
          Contact
          <br />
          A.h.fensome@gmail.com
          <br />
          07890707422
        </p>
      </div>
      <GithubLink />
    </div>
  );
}
